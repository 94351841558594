import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Container,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import InputQuestion from '../../../components/questions/InputQuestion';
import RadioQuestion from '../../../components/questions/RadioQuestion';
import '../Strength.css';
import {
  question5Answers,
  question6Answers,
  question9Answers,
  question10Answers,
  workoutRadioQuestions,
} from './questions';

const Questionnaire = () => {
  const workoutAccordionName = 'workout-questions';
  const healthAccordionName = 'health-questions';

  const [expanded, setExpanded] = useState<string | boolean>(workoutAccordionName);
  const handleChange: (panel: string) => AccordionProps['onChange'] =
    (panel: string) => (_, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formProps = Object.fromEntries(formData);
    console.log('🚀 - formProps', formProps);
  };

  return (
    <Container maxWidth="sm">
      <h1>Strength Section</h1>
      <form className="questionnaire" onSubmit={handleSubmit}>
        <Accordion
          expanded={expanded === workoutAccordionName}
          onChange={handleChange(workoutAccordionName)}
        >
          <AccordionSummary>
            <Typography>Workout questions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box flexDirection="column" display="flex" gridRowGap={25}>
              {workoutRadioQuestions.map((question) => (
                <RadioQuestion {...question} key={question.label} />
              ))}

              <InputQuestion
                label="Do you play sport or do any other form of exercise? If yes, then please
                  specify."
                name="sports"
              />

              <RadioQuestion
                label="How long do you workout/exercise for?"
                name="workoutDuration"
                answers={question5Answers}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === healthAccordionName}
          onChange={handleChange(healthAccordionName)}
        >
          <AccordionSummary>
            <Typography>Health related questions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box flexDirection="column" display="flex" gridRowGap={25}>
              <RadioQuestion label="Do you smoke?" name="smoker" answers={question6Answers} />

              <InputQuestion
                label="Do you have any medical limitations (diseases, conditions, etc)? If yes, then please
                specify."
                name="medicalHistory"
              />

              <InputQuestion
                label="Do you consume supplements? If yes, then please specify."
                name="supplements"
              />

              <RadioQuestion
                label="What is your main goal?"
                name="personalGoal"
                answers={question9Answers}
              />

              <RadioQuestion
                label="What would you like to focus on the most?"
                name="focus"
                answers={question10Answers}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default Questionnaire;
