import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { checkJWT, loginUser } from '../../api/wpHelper';
import logo from '../../assets/Logo.png';
import { userRoles } from '../../helper/user-roles';
import { ActionCreators } from '../../store/user/actions';
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  // const store = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { saveProfile } = bindActionCreators(ActionCreators, dispatch);

  async function sendUserInfo(e: React.FormEvent) {
    e.preventDefault();
    setError('');
    setLoading(true);
    const response = await loginUser({ loginId: email, password });
    setLoading(false);
    if (response.success) {
      // login successful
      const userDetails = await checkJWT(response.data.jwt);
      if (userDetails.success) {
        // Search Json object and match usernames to get role then add role property to data being saved.
        const currentUser = userRoles.find(
          (user) => user.userName === userDetails.data.user.user_nicename
        );
        userDetails.data.user.user_role = currentUser?.role ?? 'client';
        localStorage.setItem('userDetails', JSON.stringify(userDetails.data.user));
        saveProfile(userDetails.data.user);
        history.push('/home');
      }
    } else {
      setError('Incorrect Username/Email or Password. Please try again');
    }
  }

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  return (
    <Container maxWidth="sm">
      <form data-testid="login-form" onSubmit={sendUserInfo} className="login-form">
        <img src={logo} alt="logo" className="logo" />
        <FormControl className="login-form-control">
          <InputLabel htmlFor="email-input">Username/Email</InputLabel>
          <Input
            id="email-input"
            aria-describedby="email-text"
            onChange={handleEmailInput}
            value={email}
          />
          <FormHelperText id="email-text">
            This information won't be shared with others
          </FormHelperText>
        </FormControl>
        <br />
        <FormControl className="password-form-control">
          <InputLabel htmlFor="password-input">Password</InputLabel>
          <Input
            id="password-input"
            aria-describedby="password-text"
            onChange={handlePasswordInput}
            value={password}
            type="password"
          />
          <FormHelperText id="password-text">Password (required)</FormHelperText>
        </FormControl>
        <br />
        {error ? (
          <Alert data-testid="alert-popup" variant="filled" severity="error">
            Username/Email or Password is incorrect!
          </Alert>
        ) : null}
        <br />
        <div style={{ position: 'relative' }}>
          <Button variant="contained" color="primary" type="submit" disabled={loading}>
            Login
            {loading && <CircularProgress size={18} />}
          </Button>
        </div>
      </form>
    </Container>
  );
}
export default Login;
