import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  tableHead: {
    color: 'black',
  },
});

interface RowData {
  column1: number;
  column2: number;
}

function WorkoutPlan(tableInfo: { columns: string[]; rows: RowData[] }) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {Object.values(tableInfo.columns).map((column) => (
              <TableCell className={classes.tableHead}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(tableInfo.rows).map((row, key) => (
            <TableRow key={row.column1 + ' ' + key}>
              <TableCell>{row.column1}</TableCell>
              <TableCell>{row.column2}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default WorkoutPlan;
