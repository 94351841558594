export type Role = 'admin' | 'mentor' | 'client';
export type UserRole = { userName: string; role: Role };
export const userRoles: UserRole[] = [
  {
    userName: 'admin',
    role: 'admin',
  },
  {
    userName: 'Chris',
    role: 'mentor',
  },
  {
    userName: 'Karen',
    role: 'mentor',
  },
  {
    userName: 'Shaun',
    role: 'mentor',
  },
  {
    userName: 'mentor1',
    role: 'mentor',
  },
  {
    userName: 'mentor2',
    role: 'mentor',
  },
  {
    userName: 'mentor3',
    role: 'mentor',
  },
  {
    userName: 'mentor4',
    role: 'mentor',
  },
  {
    userName: 'mentor5',
    role: 'mentor',
  },
  {
    userName: 'karenclient',
    role: 'client',
  },
  {
    userName: 'karentest1',
    role: 'client',
  },
  {
    userName: 'chrischesher',
    role: 'client',
  },
  {
    userName: 'rdstest',
    role: 'client',
  },
];
