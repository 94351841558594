import { PayloadAction } from '@reduxjs/toolkit';
import { ActionType, UserInfo } from './actions';

interface StoreState {
  userDetail: Partial<UserInfo>;
}

export const userDetailKey = 'userDetails';
const storageUserDetails = localStorage.getItem('userDetails');

let userDetails = {};
if (storageUserDetails) {
  userDetails = JSON.parse(storageUserDetails);
}

const initalUser: StoreState = { userDetail: userDetails };

const userReducer = (state = initalUser, action: PayloadAction<UserInfo>) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
        userDetail: action.payload,
      };
    case ActionType.SAVE_USER:
      return {
        ...state,
        userDetail: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
