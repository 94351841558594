import { Container } from '@material-ui/core';
import ExerciseEntry from './ExerciseEntry';
import Questionnaire from './questionnaire/Questionnaire';
import './Strength.css';

function Strength() {
  return (
    <Container maxWidth="sm">
      <Questionnaire />
      <ExerciseEntry />
    </Container>
  );
}
export default Strength;
