import axios from 'axios';

export const jwtTokenKey = 'userToken';

export type Credentials = {
  loginId: string;
  password: string;
};

const regex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const simpleJWTURL = 'https://www.clswellness.com.au/?rest_route=/simple-jwt-login/v1/';
export async function loginUser(user: Credentials) {
  const isValidEmail = regex.test(user.loginId);

  const postURL =
    simpleJWTURL +
    `auth&${isValidEmail ? 'email' : 'username'}=${encodeURIComponent(
      user.loginId
    )}&password=${encodeURIComponent(user.password)}`;

  return axios
    .post(postURL)
    .then((response) => {
      if (response.status === 200) {
        // login successful
        const jwt = response.data.data.jwt;
        localStorage.setItem(jwtTokenKey, JSON.stringify(jwt));
      }
      return response.data;
    })
    .catch((err) => {
      console.error('Failed to login');
      return err;
    });
}

export async function checkJWT(jwt: string) {
  return axios
    .get(`https://clswellness.com.au/?rest_route=/simple-jwt-login/v1/auth/validate&JWT=${jwt}`)
    .then((response) => response.data)
    .catch((err) => {
      console.error('Failed to validate JWT');
      return err;
    });
}
