import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';

interface Props {
  label: string;
  name: string;
  answers: string[];
}

const RadioQuestion = ({ label, name, answers }: Props) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <RadioGroup name={name}>
      {answers.map((answer) => (
        <FormControlLabel key={answer} value={answer} control={<Radio />} label={answer} />
      ))}
    </RadioGroup>
  </FormControl>
);

export default RadioQuestion;
