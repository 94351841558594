import { Container } from "@material-ui/core";
import React from "react";

function Cardio() {
  return (
    <Container>
      <div>Cardio Module Coming Soon!</div>
    </Container>
  );
}
export default Cardio;
