import { GetProps } from 'react-redux';
import RadioQuestion from '../../../components/questions/RadioQuestion';

export const question1Answers: string[] = [
  '0 days/week',
  '1-2 days/week',
  '3-4 days/week',
  '5+ days/week',
];

type RadioQuestionProps = GetProps<typeof RadioQuestion>;

export const questionOne: RadioQuestionProps = {
  label: 'How often do you workout?',
  name: 'workoutFrequency',
  answers: ['0 days/week', '1-2 days/week', '3-4 days/week', '5+ days/week'],
};

export const questionTwo: RadioQuestionProps = {
  label: 'Where do you workout?',
  name: 'workoutLocation',
  answers: ['Home', 'Gym', 'Other'],
};

export const questionThree: RadioQuestionProps = {
  label: 'Who do you workout with?',
  name: 'workoutWith',
  answers: ['Alone', 'As a pair', 'As a group'],
};

export const workoutRadioQuestions = [questionOne, questionTwo, questionThree];

export const question5Answers: string[] = [
  '0 minutes',
  '10-20 minutes',
  '30-45 minutes',
  '1 hour - 1 hour 30 minutes',
  '2+ hours',
];

export const question6Answers: string[] = ['Yes', 'No', 'Occasionally'];
export const question9Answers: string[] = [
  'Improve Health',
  'Feel Better',
  'Look Better',
  'Build Strength',
  'Rehabilitation',
];

export const question10Answers: string[] = [
  'Strength',
  'Cardio',
  'Flexibility',
  'Weight Loss',
  'Mental Health',
];
