import { FormControl, FormLabel, Input } from '@material-ui/core';
import React from 'react';

interface Props {
  label: string;
  name: string;
}

const InputQuestion = ({ label, name }: Props) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Input name={name} />
  </FormControl>
);

export default InputQuestion;
