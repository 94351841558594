import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { store } from '../../store/reduxStore';
import Cardio from '../Cardio/Cardio';
import Flexibility from '../Flexibility/Flexibility';
import MentalHealth from '../MentalHealth/MentalHealth';
import Strength from '../Strength/Strength';
import WeightLoss from '../WeightLoss/WeightLoss';
import { firstNavItems, secondNavItems } from './homeHelper';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

function Home() {
  const user = store.getState().user.userDetail;
  const [showSideBar, setShowSideBar] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const container = window !== undefined ? () => window.document.body : undefined;

  const drawer = (
    <div data-testid="button-container">
      {[firstNavItems, secondNavItems].map((setNav, i) => (
        <div key={i + 'navItem'}>
          <Divider />
          <List>
            {setNav.map((item) => (
              <ListItem button key={item.title} onClick={() => history.push(item.route)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </List>
        </div>
      ))}
    </div>
  );
  return (
    <div data-testid="main-container">
      <AppBar data-testid="toolbar" position="fixed" className={classes.appBar}>
        <Toolbar>
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setShowSideBar(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6" noWrap>
            Logged in Home as {user.user_nicename ?? ''}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav data-testid="sidebar" className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer // only show on mobile
            container={container}
            variant="temporary"
            anchor="left"
            open={showSideBar}
            onClose={() => setShowSideBar(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer // show on bigger screen
            variant="permanent"
            open
            classes={{ paper: classes.drawerPaper }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div data-testid="routes" className={classes.toolbar} />
        <Switch>
          <Route path="/home/strength" component={Strength} />
          <Route path="/home/cardio" component={Cardio} />
          <Route path="/home/flexibility" component={Flexibility} />
          <Route path="/home/mentalhealth" component={MentalHealth} />
          <Route path="/home/weightloss" component={WeightLoss} />
        </Switch>
      </main>
    </div>
  );
}
export default Home;
