import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useState } from 'react';
import './Strength.css';
import WorkoutPlan from './WorkoutPlan';

interface Exercise {
  name: string;
  weight: number;
  reps: number;
}

function ExerciseEntry() {
  const [exerciseName, setExerciseName] = useState('');
  const [weight, setWeight] = useState(0);
  const [reps, setReps] = useState(0);

  const [newExercise, setNewExercise] = useState<Exercise>({
    name: '',
    weight: 0,
    reps: 0,
  });

  // replace with query from endpoint
  const listOfExercises: string[] = [
    'Bench Press',
    'Leg Press',
    'Squats',
    'Bent Over Rows',
    'Dead Lifts',
    'Bicep Curl',
  ];

  const addSelectedExerciseName = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target;

    if (typeof value === 'string') {
      setExerciseName(value);
    }
  };

  const addWeightInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber } = e.target;

    setWeight(valueAsNumber);
  };

  const addRepsInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber } = e.target;

    setReps(valueAsNumber);
  };

  const [showPlan, setShowPlan] = useState(false);

  //preventDefault is there so the page doesn't refresh so you can see the workoutplan appear.
  const submitExercise = (e: React.FormEvent) => {
    setNewExercise((prevState) => ({
      ...prevState,
      name: exerciseName,
      weight: weight,
      reps: reps,
    }));

    e.preventDefault();
    setShowPlan(true);
  };

  const columnHeaders: string[] = ['Weight (KG)', 'Reps (No.)'];

  const rowData = [
    { column1: 25, column2: 10 },
    { column1: 30, column2: 8 },
    { column1: 40, column2: 6 },
    { column1: 50, column2: 4 },
  ];

  return (
    <Container>
      <h2>User Enter Exercises Section</h2>
      <FormControl fullWidth className="formControl">
        <InputLabel id="exercise-select-label">Select Exercise</InputLabel>
        <Select
          id="exercise-select"
          label="Exercise"
          onChange={addSelectedExerciseName}
          defaultValue=""
        >
          {listOfExercises.map((exercise) => (
            <MenuItem key={exercise} value={exercise}>
              {exercise}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <form className="workoutplan-form" onSubmit={submitExercise}>
        <FormControl className="formControl">
          <FormLabel>Weight (KG)</FormLabel>
          <Input onChange={addWeightInfo} id="weight-input" name="weight" type="number" />
        </FormControl>
        <FormControl className="formControl">
          <FormLabel>Reps</FormLabel>
          <Input onChange={addRepsInfo} id="reps-input" name="reps" type="number" />
        </FormControl>
        <Button variant="contained" color="primary" type="submit">
          Show Plan
        </Button>
      </form>
      {showPlan ? JSON.stringify(newExercise) : null}
      {showPlan ? <WorkoutPlan columns={columnHeaders} rows={rowData} /> : null}
    </Container>
  );
}
export default ExerciseEntry;
