import { Dispatch } from 'redux';
import { Role } from '../../helper/user-roles';

export enum ActionType {
  LOGIN = 'LOGIN',
  SAVE_USER = 'SAVE_USER',
}

// This is the data type returned from WP Simple JWT Login
export type UserInfo = {
  display_name: string;
  user_email: string;
  user_login: string;
  user_nicename: string;
  user_role: Role;
};

interface LoginAction {
  type: ActionType.LOGIN;
  payload: { username: string; password: string };
}

interface SaveAction {
  type: ActionType.SAVE_USER;
  payload: Partial<UserInfo>;
}

type UserAction = LoginAction | SaveAction;

export const ActionCreators = {
  saveProfile: (userPayload: Partial<UserInfo>) => (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: ActionType.SAVE_USER,
      payload: userPayload,
    });
  },

  login: (username: string, password: string) => (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: ActionType.LOGIN,
      payload: { username, password },
    });
  },
};
