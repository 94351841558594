import AccessibilityIcon from "@material-ui/icons/Accessibility";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import HomeIcon from "@material-ui/icons/Home";
import React, { ReactNode } from "react";

type NavItem = { title: string; route: string; icon: ReactNode };

export const firstNavItems: NavItem[] = [
  { title: "Home", icon: <HomeIcon />, route: "/home" },
  { title: "Strength", icon: <FitnessCenterIcon />, route: "/home/strength" },
  { title: "Cardio", icon: <DirectionsRunIcon />, route: "/home/cardio" },
  {
    title: "Flexibility",
    icon: <AccessibilityIcon />,
    route: "/home/flexibility",
  },
];

export const secondNavItems: NavItem[] = [
  {
    title: "Mental Health",
    icon: <FavoriteIcon />,
    route: "/home/mentalhealth",
  },
  {
    title: "Weight Loss",
    icon: <DirectionsBikeIcon />,
    route: "/home/weightloss",
  },
];
