import Button from '@material-ui/core/Button/Button';
import { useHistory } from 'react-router-dom';
import { checkJWT, jwtTokenKey } from '../../api/wpHelper';
import './Main.css';

function Main() {
  const history = useHistory();
  const jwt = localStorage.getItem(jwtTokenKey);

  // TODO convert to async and set loading
  if (jwt) {
    const parsedJWT = JSON.parse(jwt);
    checkJWT(parsedJWT).then((response) => {
      if (response.status === 200) {
        history.push('/home');
      }
    });
  }
  function goToLogin() {
    history.push('/login');
  }
  return (
    <div className="pre-login">
      <div className="login-logo">
        <h1>CLS Wellness</h1>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="pre-login_button"
          onClick={goToLogin}
        >
          Login
        </Button>
      </div>
    </div>
  );
}
export default Main;
