import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import Login from '../Login/Login';
import Main from './Main';
const history = createBrowserHistory();

// Main Routes
const App = () => {
  document.title = 'CLS Wellness';
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/login" component={Login} />
        {/* TODO make private routes */}
        <Route path="/home" component={Home} />
      </Switch>
    </Router>
  );
};
export default App;
